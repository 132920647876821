import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { initializePaddleBilling } from "../utils/paddleUtils";
import PageLayout from "../layout/PageLayout";
import "../styles.css";

const PaymentPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userData, opportunityId, venueId } = location.state || {};

  useEffect(() => {
    if (!userData || !opportunityId || !venueId) {
      console.error("Missing required data for payment.");
      navigate("/");
      return;
    }

    const targetContainer = document.querySelector(".checkout-container");
    if (targetContainer) {
      initializePaddleBilling({
        targetClassName: "checkout-container",
        userData,
        opportunityId,
        venueId,
        navigate,
      });
    } else {
      console.error("Checkout container not found.");
    }
  }, [navigate, userData, opportunityId, venueId]);

  return (
    <PageLayout type="meta">
        <h2>Add your payment details</h2>
        <p>
          Please provide your payment details to complete the subscription.
        </p>
        <div className="checkout-container"></div>
    </PageLayout>
  );
};

export default PaymentPage;
