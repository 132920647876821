import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SubscriptionType from "./pages/SubscriptionType";
import TermsPage from "./pages/TermsPage";
import PaymentPage from "./pages/PaymentPage";
import ThankYouPage from "./pages/ThankYouPage";
import ErrorPage from "./pages/ErrorPage";
import DefaultPage from "./pages/DefaultPage";
import SmartChannelPage from "./pages/SmartChannelPage";
import ListingPage from "./pages/ListingPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SubscriptionType />} />
        <Route path="/meta" element={<TermsPage />} />
        <Route path="/payment" element={<PaymentPage />} />
        <Route path="/thank-you" element={<ThankYouPage />} />
        <Route path="/types" element={<DefaultPage />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/smartchannel" element={<SmartChannelPage />} />
        <Route path="/listing" element={<ListingPage />} />
      </Routes>
    </Router>
  );
}

export default App;
