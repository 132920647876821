import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

const SubscriptionType = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // Extract all URL parameters
  const params = Object.fromEntries([...searchParams.entries()]);
  const { type } = params;

  useEffect(() => {
    if (!type) {
      navigate("/types");
      return;
    }

    // Route based on `type`, passing all parameters
    switch (type.toLowerCase()) {
      case "meta":
        navigate("/meta", { state: params });
        break;

      case "listing":
        navigate("/listing", { state: params });
        break;

      case "smartchannel":
        navigate("/smartchannel", { state: params });
        break;

      default:
        navigate("/types");
    }
  }, [type, params, navigate]);

  return <div>Loading...</div>; // Optional loading or spinner
};

export default SubscriptionType;
