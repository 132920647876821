export const initializePaddleBilling = async ({
  targetClassName,
  userData,
  opportunityId,
  venueId,
  navigate,
}) => {
  try {
    const Paddle = window.Paddle;

    if (!Paddle) {
      console.error("Paddle script not loaded.");
      return;
    }

    // Set the Paddle environment
    if (process.env.REACT_APP_PADDLE_ENVIRONMENT === "sandbox") {
      Paddle.Environment.set("sandbox");
    }

    // define items
    const subscriptionItemsList = [
      {
        priceId: process.env.REACT_APP_PADDLE_SUBSCRIPTION_PRICE_ID,
        quantity: 1,
      },
    ];

    Paddle.Initialize({
      token: process.env.REACT_APP_PADDLE_TOKEN,
      eventCallback: (event) => {
        if (event.name === "checkout.completed") {
          console.log("Paddle event:", event);
          navigate("/thank-you");
        }
      }
    });

    Paddle.Checkout.open({
      items: subscriptionItemsList,
      settings: {
        displayMode: "inline",
        frameInitialHeight: 500,
        frameTarget: targetClassName,
        frameStyle: "width: 100%; min-width: 312px; background-color: transparent; border: none;",
        allowLogout: false,
        showAddTaxId: false,
      },
      customer: {
        email: userData.email,
        address: {
          country_code: userData.company?.country,
          postal_code: userData.company?.postalCode,
          city: userData.company?.city,
          first_line: userData.company?.address,
        },
        business: {
          name: userData.company?.name,
          tax_identifier: userData.company?.vatNumber,
        },
      }
    });

  } catch (error) {
    console.error("Error initializing Paddle:", error);
  }
};
