import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getToken,
  setAuthToken,
  fetchListingCommercialConditions,
  fetchUserDetails,
} from "../utils/api";
import CommercialConditions from "../components/CommercialConditions";
import TermsModal from "../components/TermsModal";
import ErrorMessage from "../components/ErrorMessage";
import PageLayout from "../layout/PageLayout";
import "../styles.css";

const TermsPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [commercialConditions, setCommercialConditions] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Extract parameters
  const { uid, hash, vid, type } = state || {};

  useEffect(() => {
    if (!uid || !hash || type !== "listing") {
      console.error("Invalid access to TermsPage.");
      navigate("/error", { state: { message: "Invalid flow type or missing parameters." } });
      return;
    }

    let isMounted = true;

    const fetchData = async () => {
      try {
        const accessToken = await getToken(uid, hash);
        if (!isMounted) return;
        setAuthToken(accessToken);

        const userDetails = await fetchUserDetails();
        if (!isMounted) return;
        setUserDetails(userDetails);

        const partnerId = userDetails.partner?.id;
        if (!partnerId) {
          throw new Error("Partner ID not found in user details.");
        }

        const conditions = await fetchListingCommercialConditions(partnerId);
        if (!isMounted) return;
        setCommercialConditions(conditions);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load commercial conditions. Please try again.");
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [uid, hash, type, navigate]);

  const handleAgree = () => {
    navigate("/payment", {
      state: {
        userData: userDetails,
        opportunityId: commercialConditions.id,
        venueId: vid,
      },
    });
  };

  return (
    <PageLayout type={type}>
      
      { error && <ErrorMessage message={error} /> }

      {loading ? (
        <div className="loading-container">
          <img
            src="https://mozrest-statics.s3.eu-west-1.amazonaws.com/others/spinner.gif" // Replace with your loading spinner URL
            alt="Loading"
            className="loading-spinner"
          />
        </div>
      ) : (
        <>
          <CommercialConditions conditions={commercialConditions} type={type} />
      <p>
        By clicking on "I Agree" you confirm your acceptance of{" "}
        <span className="link-terms" onClick={() => setIsModalOpen(true)}>
          Mozrest's general terms and conditions
        </span>{" "}
        and will be redirected to payment.
      </p>
      <div className="button-container">
        <button className="button" onClick={handleAgree}>
          I Agree
        </button>
      </div>
      {isModalOpen && (
        <TermsModal
          onClose={() => setIsModalOpen(false)}
          src="https://docs.google.com/document/d/e/2PACX-1vSyv27UAxMv6KNrJluqWoq8j6qiWTKIGdRPVaJzmi5Pyma-uzTPVGq8B5Gmie1jWmy8QeJxV3SfjEQi/pub?embedded=true"
            />
          )}
        </>
      )}
    </PageLayout>
  );
};

export default TermsPage;